import collab from 'assets/images/collab.png';
import jpass from 'assets/images/jpass.png';

const DOWNLOAD_APK_BY_PLATFORM = {
  COLLAB: {
    title: 'Collab',
    icon: collab,
    btntext: 'Coming soon',
    body: 'Collab Mobile is designed to be a key component of our overall web app, with a specific focus on notification, collaboration, and interaction between end users.',
  },
  JPASS: {
    title: 'Jpass',
    icon: jpass,
    btntext: 'Download App',
    body: 'J-PASS, The most integrated digital platform for Management, collaboration and site control.',
  },
};
export default DOWNLOAD_APK_BY_PLATFORM;
