import PropTypes from 'prop-types';
import { Stack, Typography } from '@mui/material';
import { formatDate } from 'utils/dateUtils';

function ProjectListItem({ project, updateProject }) {
  return (
    <Stack
      onClick={() => {
        updateProject(project?.id);
      }}
      className="listing_item_row user__item"
    >
      <Stack gap={10} direction="row" alignItems="center" justifyItems="center">
        <Typography variant="subtitle1_HelveticaNeue_Medium" color="text.main">
          {project?.name}
        </Typography>
      </Stack>
      <Stack>
        <Typography variant="subtitle1_HelveticaNeue_Medium" color="text.gray">
          {project?.number}
        </Typography>
      </Stack>
      <Stack>
        <Typography variant="subtitle1_HelveticaNeue_Medium" color="text.gray">
          {project?.status}
        </Typography>
      </Stack>
      <Stack>
        <Typography variant="subtitle1_HelveticaNeue_Regular" color="text.gray">
          {formatDate(project?.startDate)}
        </Typography>
      </Stack>
      <Stack>
        <Typography variant="subtitle1_HelveticaNeue_Regular" color="text.gray">
          {formatDate(project?.forecastDate)}
        </Typography>
      </Stack>
    </Stack>
  );
}

ProjectListItem.propTypes = {
  project: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    number: PropTypes.string,
    status: PropTypes.string,
    startDate: PropTypes.instanceOf(Date),
    forecastDate: PropTypes.instanceOf(Date),
  }),
  updateProject: PropTypes.func,
};

export default ProjectListItem;
