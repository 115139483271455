import { Features, PlatformEnum } from 'utils/constants/Features';

export const TAB_FILTER_NAME = {
  ALL: {
    code: 'ALL',
    label: 'All',
  },
  JESA: {
    code: 'JESA',
    label: 'Jesa',
  },
  EXTERNAL_CLIENT: {
    code: 'EXTERNAL_CLIENT',
    label: 'Client',
  },
  EXTERNAL_CONTRACTOR: {
    code: 'EXTERNAL_CONTRACTOR',
    label: 'Contractor',
  },
};

export const PLATFORM_WITHOUT_PLATFORM = [
  'COLLAB_MAADEN',
  'COLLAB_PROGRAM_OCP',
  'QA',
  'ACONEX',
];
export const PLATFORM_FEATURE = new Map<string, string>([
  [PlatformEnum.COLLAB, Features.DS_PROJECT_MEMBERS_COLLAB],
  [PlatformEnum.JPASS, Features.DS_PROJECT_MEMBERS_JPASS],
  [PlatformEnum.ESP, Features.DS_PROJECT_MEMBERS_ESP],
]);
