export const USER_TYPE = {
  EXTERNAL: {code: 'EXTERNAL', label: 'External'},
  INTERNAL: {code: 'INTERNAL', label: 'Internal'},
};
export const USER_STATUS = {
  ACTIVE: {code: 'ACTIVE', label: 'Active'},
  INACTIVE: {code: 'INACTIVE', label: 'Inactive'},
};
export const EXTERNAL_ROLE = {
  EXTERNAL_CLIENT: 'EXTERNAL_CLIENT',
  EXTERNAL_CONTRACTOR: 'EXTERNAL_CONTRACTOR',
};


