import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Stack, Typography} from '@mui/material';
import FormSelect from 'components/FormSelect';
import EditIconProfile from 'assets/svgs/componentsIcons/EditIconProfile';
import {DeleteWL} from 'assets/svgs/componentsIcons';
import {useEditUserProjectProfileMutation} from "services/apis/user-project-profile";
import {useDispatch} from "react-redux";
import {addUpdatedItems, removeUpdatedItem} from "services/slices/user-management/user-project-profile";

function UserProjectProfileItem({item, handleGetUserProjectProfiles, profiles}) {
  const dispatch = useDispatch();
  const [selectedProfile, setSelectedProfile] = useState(item?.profile);
  const [isEditProfile, setIsEditProfile] = useState(false);
  const [doEditUserProjectProfile,
    {
      isSuccess: editUserProjectProfileSuccess
    }] = useEditUserProjectProfileMutation();

  const handleSaveEditProfile = () => {
    if (item?.profile?.code !== selectedProfile?.code) {
      doEditUserProjectProfile({...item, profile: selectedProfile})
    } else {
      setIsEditProfile(false);
    }
  };
  const handleClickOnEdit = () => {
    setIsEditProfile((prevState) => !prevState);
  };
  const handleChange = (value) => {
    if (selectedProfile?.code !== value?.code) {
      dispatch(addUpdatedItems(value?.id));
    }
    setSelectedProfile(value);
  };
  useEffect(() => {
    if (editUserProjectProfileSuccess) {
      dispatch(removeUpdatedItem(selectedProfile?.id));
      handleGetUserProjectProfiles();
      setIsEditProfile(false);
    }
  }, [editUserProjectProfileSuccess]);


  return (
    <>
      <tr key={item?.id}>
        <td> {item?.userProject?.project?.name} </td>
        <td> {item?.userProject?.project?.number} </td>
        <td> {item?.userProject?.role?.label} </td>
        <td>
          {!isEditProfile ? (
            <Typography variant="subtitle2_HelveticaNeue_Regular">
              {item?.profile?.name}
            </Typography>
          ) : (
            <FormSelect
              customClassName="profileSelect"
              menuItems={profiles}
              onChange={(e) => {
                handleChange(e.target.value);
              }}
              value={profiles?.find(
                (profile) => profile?.name === selectedProfile?.name,
              )}
              valueKey="name"
            />
          )}
        </td>
        <td>
          <Stack className="btnManagers">
            {!isEditProfile ? (
              <button
                type="button"
                className="editUser ml-10"
                onClick={handleClickOnEdit}
              >
                <EditIconProfile/>
                <Typography
                  variant="subtitle2_HelveticaNeue_Regular"
                  color="primary.main"
                >
                  Edit profile
                </Typography>
              </button>
            ) : (
              <button
                type="button"
                className="saveUser ml-10"
                onClick={handleSaveEditProfile}
              >
                <Typography
                  variant="subtitle2_HelveticaNeue_Regular"
                  color="common.white"
                >
                  Save
                </Typography>
              </button>
            )}
            <button type="button" className="deleteBtn" onClick={() => {
            }}>
              <DeleteWL/>
            </button>
          </Stack>
        </td>
      </tr>
    </>
  )
}


UserProjectProfileItem.propTypes = {
  item: PropTypes.object,
  profiles: PropTypes.array,
  handleGetUserProjectProfiles: PropTypes.func,
};
export default UserProjectProfileItem;
