import React from 'react';
import PropTypes from 'prop-types';
import UserProjectProfileItem from "./UserProjectProfileItem";

function UserProjectProfileTable({userProjectProfiles, handleGetUserProjectProfiles, profiles}) {
  return (
    <table className="mt-20 p">
      <thead>
      <tr>
        <th>Project Name</th>
        <th>Project Number</th>
        <th>Project role</th>
        <th>Project profile</th>
        <th/>
        <th/>
      </tr>
      </thead>
      <tbody>
      {userProjectProfiles?.content?.map((item) => (
        <UserProjectProfileItem
          key={item?.id}
          item={item}
          handleGetUserProjectProfiles={handleGetUserProjectProfiles}
          profiles={profiles}/>
      ))}
      </tbody>
    </table>
  );
}

UserProjectProfileTable.propTypes = {
  profiles: PropTypes.array,
  handleGetUserProjectProfiles: PropTypes.func,
  userProjectProfiles: PropTypes.array,
};
export default UserProjectProfileTable;
