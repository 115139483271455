import React from 'react';
import PropTypes from 'prop-types';
import { CloseIcon } from 'assets/svgs/componentsIcons';
import CustomModal from 'components/StyledComponents/CustomModal';
import { Modal } from '@mui/material';
import { CloseButton, MainButton } from 'components/Buttons';
import loader from 'assets/images/loader.png';
import { PlatformEnum } from 'services/apis/platform';
import DOWNLOAD_APK_BY_PLATFORM from './constants';

function DownloadPopin({ onClose, platformName, handelOnClick, isLoading }) {
  return (
    <Modal
      open
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <CustomModal className="bg-grey" modalWidth="416px">
        <div className="modal-header">
          <span className="icon mr-15">
            <img src={DOWNLOAD_APK_BY_PLATFORM[platformName].icon} alt="collab" />
          </span>
          <h2 className="modal-title">
            {DOWNLOAD_APK_BY_PLATFORM[platformName].title} Android
          </h2>
          <CloseButton className="ml-auto" type="button" onClick={onClose}>
            <CloseIcon />
          </CloseButton>
        </div>
        <div className="modal-head-wrapper mt-60">
          <div className="modal-head">
            <h2 className="modal-title dark">
              Download {DOWNLOAD_APK_BY_PLATFORM[platformName].title} for Mobile
            </h2>

            <p className="modal-description mt-15">
              {DOWNLOAD_APK_BY_PLATFORM[platformName].body}
            </p>
          </div>
        </div>

        <div className="modal-action-wrapper justify-center">
          {isLoading ? (
            <div className="donwloadLoaderContainer">
              <div className="donwloadLoader">
                <img src={loader} alt="loader" />
              </div>
              <p>Loading</p>
            </div>
          ) : (
            <MainButton
              className={`download ${
                platformName === PlatformEnum.COLLAB ? 'disable' : ''
              } `}
              onClick={() => handelOnClick(platformName)}
            >
              {DOWNLOAD_APK_BY_PLATFORM[platformName].btntext}
            </MainButton>
          )}
        </div>
      </CustomModal>
    </Modal>
  );
}

DownloadPopin.propTypes = {
  onClose: PropTypes.func,
  platformName: PropTypes.string,
  handelOnClick: PropTypes.func,
  isLoading: PropTypes.bool,
};

export default DownloadPopin;
