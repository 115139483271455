/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useEffect, useState } from 'react';
import SplashScreenCircles from 'assets/svgs/componentsIcons/SplashScreenCircles';
import jpassLogo from 'assets/images/landingPage/jpassLogo.png';
import digitalSpaceLogo from 'assets/images/landingPage/digitalSpaceLogo.png';
import circle from 'assets/images/landingPage/circle.png';

import collab from 'assets/images/landingPage/collab.png';
import csp from 'assets/images/landingPage/csp.png';
import BLOOM from 'assets/images/landingPage/BLOOM.png';
import SAFRAN from 'assets/images/landingPage/SAFRAN.png';
import ACONEX from 'assets/images/landingPage/ACONEX.png';
import OPC from 'assets/images/landingPage/OPC.png';
import WORK_PACKS from 'assets/images/landingPage/WORK_PACKS.png';
import PROCORE from 'assets/images/landingPage/PROCORE.png';
import SMART_COMPLETIONS from 'assets/images/landingPage/SMART_COMPLETIONS.png';
import AVEVA_ERM from 'assets/images/landingPage/AVEVA_ERM.png';
import AUTODESK_CC from 'assets/images/landingPage/AUTODESK_CC.png';
import qa from 'assets/images/landingPage/qa.png';
import welcomeTo from 'assets/images/landingPage/welcomeTo.png';
import jesaLogo from 'assets/images/landingPage/jesaLogo.png';
import hse from 'assets/images/landingPage/hse.png';

import { AppButton } from 'components/Buttons';
import { Typography } from '@mui/material';
import { useCustomAuth } from 'providers/auth-provider';
import urlPlatform from 'endpoints.config';
import { api } from 'services/apis';
import { useLazyDownloadApkByPlatformQuery } from 'services/apis/platform';
import handleBlobReceived from 'utils/blobUtils';
import DownloadPopin from 'components/DownloadPopin';
import { useLazyGetUserAccessToProgramViewQuery } from 'services/apis/user-project-profile';
import ConfirmationPopup from 'components/ConfirmationPopin';
import { POPUP_TYPE } from 'components/ConfirmationPopin/constants';
import { useHasAccessToSeniorManagementViewQuery } from 'services/apis/user';
import Wrapper from './Wrapper';
import ProgramSettingsBtn from './ProgramSettingsBtn';
import BackToBtn from './BackToBtn';
import { DOWNLOAD_APK, PLATFORMS, SUB_PLATFORMS } from './constants';
import Logout from '../Logout';

function AppSplashPage() {
  // first step: rotation
  // second step: scale & translate down
  //  third step: translate up
  // fourth step:  translate up & merge circles

  const LOG_IN_ANIMATION_DELAY = 1000;
  const ANIMATION_STEP1_DELAY = 1000; // EQUAL TRANSITION DURATION
  const ANIMATION_STEP2_DELAY = 400;
  const MARGIN_SCREEN_RATIO = 0.15;
  const SCALING_RATIO = window.innerHeight / 600 - MARGIN_SCREEN_RATIO;
  const { user, loginWithRedirect, isAuthenticated } = useCustomAuth();
  const { data: connectedUser } = api.endpoints.getConnectedUser.useQueryState();
  const [showSubPlatforms, setShowSubPlatforms] = useState(false);
  const [handleShowChild, setHandleShowChild] = useState(false);
  const [clickedPlatform, setClickedPlatform] = useState(PLATFORMS.COLLAB);
  const [showDownloadApk, setDownloadApk] = useState(false);
  const [platformName, setPlatformName] = useState('');
  const [accessProgramView, setAccessProgramView] = useState(false);
  const [openBlocAccessModal, setOpenBlocAccessModal] = useState(false);
  const jesaMoonLogo = document.querySelector('.digitalSpaceLogo');
  const [doDownloadApk, { data: apk, originalArgs: arg, isFetching: downloadLoading }] =
    useLazyDownloadApkByPlatformQuery();
  const [doGetUserAccessToProgramView] = useLazyGetUserAccessToProgramViewQuery();
  const { data: hasAccessToSeniorManagementView } =
    useHasAccessToSeniorManagementViewQuery();
  const handleToggleOrbitsName = (isShow) => {
    const orbits = document.getElementsByClassName('orbits');
    if (isShow) {
      setTimeout(() => {
        Array.from(orbits).forEach((item) => {
          item.style.opacity = 1;
          item.style.transitionDuration = '0.5s';
        });
      }, ANIMATION_STEP2_DELAY * 2);
    } else {
      setTimeout(() => {
        Array.from(orbits).forEach((item) => {
          item.style.opacity = 0;
        });
      }, ANIMATION_STEP2_DELAY);
    }
  };
  useEffect(() => {
    if (connectedUser) {
      doGetUserAccessToProgramView(connectedUser?.id).then(({ data }) => {
        setAccessProgramView(data);
      });
    }
  }, [connectedUser]);
  /* STEP 4 Create a 3D animation effect with circles TO TOP  */
  const animationStep3 = () => {
    document.getElementById('bigCircle').style.transform =
      'rotateX(-110deg) scale(1.75) translateY(8%)';

    document.getElementById('mediumCircle').style.transform =
      'rotateX(71deg) scale(1.6) translateY(-28.5%)';

    document.getElementById('smallCircle').style.transform =
      'rotateX(252deg) scale(1.3) translateY(59.5%)';
    document.getElementById(
      'circleWrapper',
    ).style.transform = `scale(${SCALING_RATIO}) translateY(14%)`;
    document.getElementById('digitalSpaceLogo').style.cssText =
      'top : 30% ; z-index : 1;transform: translate(-50% , -50%) scale(1.5);';

    document.getElementById('splashScreenWrapper').classList.add('backgroundTranslation');

    document.getElementById('welcome').style.cssText = 'left : 5% ; opacity :1';
    document.getElementById('avatar').style.opacity = '1';
    handleToggleOrbitsName(true);
    const logos = document.getElementsByClassName('logoWrapper');
    setTimeout(() => {
      Array.from(logos).forEach((item) => {
        const { style } = item;
        if (!item.classList.contains('subPlatformLogo')) {
          style.opacity = 1;
        }
      });
    }, ANIMATION_STEP2_DELAY);
  };

  /* STEP 2 Create a 3D animation effect with circles TO BOTTOM */
  const animationStep2 = () => {
    const flipCircles = document.getElementsByClassName('flipCircle');
    /* eslint no-param-reassign: "error" */
    Array.from(flipCircles).forEach((item) => {
      item.style.transitionDuration = '0.5s';
    });
    document.getElementById('bigCircle').style.transform =
      'rotateX(-113deg) scale(1.75) translateY(3%)';

    document.getElementById('mediumCircle').style.transform =
      'rotateX(68deg) scale(1.6) translateY(5.1%)';

    document.getElementById('smallCircle').style.transform =
      'rotateX(240deg) scale(1.1) translateY(-10.5%)';

    document.getElementById('digitalSpaceLogo').style.cssText =
      'transform:translate(-50% , -50%) scale(1.5); top: 51.5% ;';

    setTimeout(() => {
      animationStep3();
    }, ANIMATION_STEP2_DELAY);
  };

  /* STEP 1 Rotate circles with animation (transition) */
  const animationStep1 = () => {
    setTimeout(() => {
      animationStep2();
    }, ANIMATION_STEP1_DELAY);
    document.getElementById('bigCircle').style.transform = 'rotateX(-116deg)';
    document.getElementById('mediumCircle').style.transform = 'rotateX(63deg)';
    document.getElementById('smallCircle').style.transform = 'rotateX(240deg) ';
    document.getElementById('smallCircle').style.transform = 'rotateX(240deg) ';
    document.getElementById('digitalSpaceLogo').classList.add('toNextStepAnimation');
    document.querySelector('.loginPage').style.pointerEvents = 'none';
  };

  const logInAnimation = () => {
    document.getElementById('loginCard').classList.add('zoomOutAnimation');
    document.getElementById('circleWrapper').classList.remove('zoomOut');
    document.querySelector('.logo').classList.add('fadeOut');
    setTimeout(() => {
      animationStep1();
    }, ANIMATION_STEP1_DELAY);
  };
  useEffect(() => {
    if (isAuthenticated) {
      setTimeout(() => {
        logInAnimation();
      }, LOG_IN_ANIMATION_DELAY);
    }
  }, [isAuthenticated]);

  const handleLoginClick = async () => {
    await loginWithRedirect();
  };

  useEffect(() => {
    if (handleShowChild) {
      const logos = document.getElementsByClassName('logoWrapper');
      Array.from(logos).forEach((item) => {
        if (showSubPlatforms) {
          if (item.classList.contains('subPlatformLogo')) {
            // this useCase is when we click on Platform Icon, and we get the platform children
            item.classList.add('showElement');
            jesaMoonLogo.style.opacity = 0;
            item.classList.remove('hideElement');
          } else {
            item.classList.remove('showElement');
            item.classList.add('hideElement');
          }
        } else if (item.classList.contains('subPlatformLogo')) {
          item.classList.remove('showElement');
          item.classList.add('hideElement');
        } else {
          item.classList.add('showElement');
          item.classList.remove('hideElement');
        }
      });
    }
  }, [showSubPlatforms]);

  const handleToggleSubPlatforms = () => {
    setShowSubPlatforms(!showSubPlatforms);
  };

  const handleCloneClickedPlatform = (e) => {
    const DISTANCE_DIFF_X = 2.4;
    const DISTANCE_DIFF_Y = 4.1;
    const clonedElement = e.currentTarget.cloneNode(true);

    clonedElement.style.cssText = `
    position: absolute;
    left: calc(${e.target.getBoundingClientRect().left}px + ${DISTANCE_DIFF_X}%);
    top: calc(${e.target.getBoundingClientRect().top}px + ${DISTANCE_DIFF_Y}%);
    width: 150px;
    height: 150px;
    transform: scale(1.45);
    transition:  all 500ms linear;
    `;
    const [image] = clonedElement.childNodes;
    image.style.cssText = `height : 100%`;
    clonedElement.classList.add('clonedElement');
    clonedElement.classList.remove('hideElement');
    document.querySelector('.loginPageWrapper').parentNode.appendChild(clonedElement);

    setTimeout(() => {
      clonedElement.style.cssText = `
        position: absolute;
        left : 50%;
        top : 30%;
        width: 150px;
        height: 150px;
        transform: translate(-50% , -50%) scale(1.45);
        transition:  all 500ms linear;`;
    }, 10);
  };

  const handleShowPlatforms = () => {
    document.querySelector('.clonedElement').remove();
    jesaMoonLogo.style.opacity = 1;
    jesaMoonLogo.style.transition = 'opacity 500ms linear';
  };

  /* eslint-disable no-unused-vars */
  const handleSubPlatforms = (e, platformCode) => {
    e.preventDefault();
    handleToggleSubPlatforms();
    setHandleShowChild(true);
    handleCloneClickedPlatform(e);
    handleToggleOrbitsName(false);
    setClickedPlatform(platformCode);
  };

  const handleBackToPlatforms = () => {
    handleToggleSubPlatforms();
    handleShowPlatforms();
  };

  const handleRedirectUrl = (e, url, platform = '') => {
    if (url === '#') {
      e.preventDefault();
    }
    if (url === DOWNLOAD_APK && platform !== '') {
      setDownloadApk(!showDownloadApk);
      setPlatformName(platform);
      e.preventDefault();
    }
  };
  useEffect(() => {
    if (apk) {
      handleBlobReceived(apk, `${arg.platform.toLowerCase()}.apk`);
    }
  }, [apk]);
  const handelDownloadApk = (platform) => doDownloadApk({ platform });

  return (
    <Wrapper id="splashScreenWrapper" className="loginPageWrapper ">
      <div className="loginPage">
        <div className="logoCardContainer">
          <div className="logo">
            <img src={jesaLogo} alt="jesa logo" />
          </div>
          <div id="loginCard" className="loginCard">
            <Typography variant="h2_Nasalization_Regular" color="text.main">
              Welcome
            </Typography>
            <section className="content">
              <Typography variant="h6_Montserrat_Medium" color="text.main">
                {isAuthenticated
                  ? user.name
                  : 'Please use your JESA credentials to login. You can always contact your System Admin if you need support.'}
              </Typography>
            </section>
            {!isAuthenticated && (
              <AppButton
                customClass="primary"
                label="Login with Microsoft"
                labelVariant="h6_HelveticaNeue_Bold"
                onClick={handleLoginClick}
              />
            )}
          </div>
        </div>
      </div>

      <span id="welcome">
        <img src={welcomeTo} alt="WelcomeTo logo" />
      </span>
      <span id="avatar">
        <Logout isLanding />
      </span>

      <span id="digitalSpaceLogo" className="digitalSpaceLogo">
        <img src={circle} alt="circle Bg" />
        <img src={digitalSpaceLogo} alt="digital space logo" />
      </span>

      <div
        className="circleWrapper zoomOut"
        id="circleWrapper"
        style={{
          transform: `scale(${SCALING_RATIO})`,
          transformOrigin: 'center',
          transition: 'all 0.5s',
        }}
      >
        <div className="platforms">
          <div className="smallEllipseWrapper">
            <a
              onClick={(e) => {
                handleRedirectUrl(e, urlPlatform.COLLAB_URL);
                handleSubPlatforms(e, PLATFORMS.COLLAB);
              }}
              className="smallEllipse logoWrapper"
              href={urlPlatform.COLLAB_URL}
            >
              <div className="platformLogoItem">
                <img src={collab} alt="collab logo" />
                <Typography color="light.main" variant="subtitle1_HelveticaNeue_Regular">
                  Collab 360
                </Typography>
              </div>
            </a>
            <a
              className="smallEllipse logoWrapper"
              onClick={(e) => {
                handleRedirectUrl(e, urlPlatform.COLLAB_URL);
                handleSubPlatforms(e, PLATFORMS.JPASS);
              }}
              href={urlPlatform.JPASS_URL}
            >
              <div className="platformLogoItem">
                <img src={jpassLogo} alt="jpass logo" />
                <Typography color="light.main" variant="subtitle1_HelveticaNeue_Regular">
                  J-Pass
                </Typography>
              </div>
            </a>
          </div>

          <div className="mediumEllipseWrapper">
            <a
              onClick={(e) => handleRedirectUrl(e, urlPlatform.BLOOM)}
              className="mediumEllipse logoWrapper"
              href={urlPlatform.BLOOM}
            >
              <div className="platformLogoItem">
                <img src={BLOOM} alt="BLOOM logo" />
                <Typography color="light.main" variant="subtitle1_HelveticaNeue_Regular">
                  Bloom
                </Typography>
              </div>
            </a>

            <a
              onClick={(e) => handleRedirectUrl(e, urlPlatform.HSE_URL)}
              className="mediumEllipse logoWrapper"
              href={urlPlatform.HSE_URL}
            >
              <div className="platformLogoItem">
                <img src={hse} alt="hse logo" />
                <Typography color="light.main" variant="subtitle1_HelveticaNeue_Regular">
                  HSE Platform
                </Typography>
              </div>
            </a>
            <a
              onClick={(e) => handleRedirectUrl(e, urlPlatform.CSP_URL)}
              className="mediumEllipse logoWrapper"
              href={urlPlatform.CSP_URL}
            >
              <div className="platformLogoItem">
                <img src={csp} alt="csp logo" />
                <Typography color="light.main" variant="subtitle1_HelveticaNeue_Regular">
                  ESP
                </Typography>
              </div>
            </a>

            <a
              onClick={(e) => handleRedirectUrl(e, urlPlatform.QA_URL)}
              className="mediumEllipse logoWrapper"
              href={urlPlatform.QA_URL}
            >
              <div className="platformLogoItem">
                <img src={qa} alt="qa logo" />
                <Typography color="light.main" variant="subtitle1_HelveticaNeue_Regular">
                  TIQAD
                </Typography>
              </div>
            </a>
          </div>

          <div className="bigEllipseWrapper">
            <a
              onClick={(e) => handleRedirectUrl(e, urlPlatform.SAFRAN)}
              className="bigEllipse  logoWrapper"
              href={urlPlatform.SAFRAN}
              target="_blank"
              rel="noreferrer"
            >
              <div className="platformLogoItem">
                <img src={SAFRAN} alt="SAFRAN logo" />
                <Typography color="light.main" variant="subtitle1_HelveticaNeue_Regular">
                  SAFRAN
                </Typography>
              </div>
            </a>
            <a
              onClick={(e) => handleRedirectUrl(e, urlPlatform.OPC)}
              className="bigEllipse logoWrapper"
              href={urlPlatform.OPC}
              target="_blank"
              rel="noreferrer"
            >
              <div className="platformLogoItem">
                <img src={OPC} alt="Opc logo" />
                <Typography color="light.main" variant="subtitle1_HelveticaNeue_Regular">
                  OPC
                </Typography>
              </div>
            </a>
            <a
              onClick={(e) => handleRedirectUrl(e, urlPlatform.WORK_PACKS)}
              className="bigEllipse logoWrapper"
              href={urlPlatform.WORK_PACKS}
              target="_blank"
              rel="noreferrer"
            >
              <div className="platformLogoItem">
                <img src={WORK_PACKS} alt="work packs logo" />
                <Typography color="light.main" variant="subtitle1_HelveticaNeue_Regular">
                  WorkPacks
                </Typography>
              </div>
            </a>
            <a
              onClick={(e) => handleRedirectUrl(e, urlPlatform.PROCORE)}
              className="bigEllipse logoWrapper"
              href={urlPlatform.PROCORE}
              target="_blank"
              rel="noreferrer"
            >
              <div className="platformLogoItem">
                <img src={PROCORE} alt="procore logo" />
                <Typography color="light.main" variant="subtitle1_HelveticaNeue_Regular">
                  PROCORE
                </Typography>
              </div>
            </a>
            <a
              onClick={(e) => handleRedirectUrl(e, urlPlatform.SMART_COMPLETIONS)}
              className="bigEllipse logoWrapper"
              href={urlPlatform.SMART_COMPLETIONS}
              target="_blank"
              rel="noreferrer"
            >
              <div className="platformLogoItem">
                <img src={SMART_COMPLETIONS} alt="Smart Completions logo" />
                <Typography color="light.main" variant="subtitle1_HelveticaNeue_Regular">
                  HxGN Smart Completions
                </Typography>
              </div>
            </a>
            <a
              onClick={(e) => handleRedirectUrl(e, urlPlatform.AVEVA_ERM)}
              className="bigEllipse logoWrapper"
              href={urlPlatform.AVEVA_ERM}
              target="_blank"
              rel="noreferrer"
            >
              <div className="platformLogoItem">
                <img src={AVEVA_ERM} alt="AVEVA ERM logo" />
                <Typography color="light.main" variant="subtitle1_HelveticaNeue_Regular">
                  AVEVA ERM
                </Typography>
              </div>
            </a>
            <a
              onClick={(e) => handleRedirectUrl(e, urlPlatform.AUTODESK_CC)}
              className="bigEllipse logoWrapper"
              href={urlPlatform.AUTODESK_CC}
              target="_blank"
              rel="noreferrer"
            >
              <div className="platformLogoItem">
                <img src={AUTODESK_CC} alt="AUTODESK C.C logo" />
                <Typography color="light.main" variant="subtitle1_HelveticaNeue_Regular">
                  Autodesk C.C.
                </Typography>
              </div>
            </a>
            <a
              onClick={(e) => handleRedirectUrl(e, urlPlatform.ACONEX_URL)}
              className="bigEllipse logoWrapper"
              href={urlPlatform.ACONEX_URL}
              target="_blank"
              rel="noreferrer"
            >
              <div className="platformLogoItem">
                <img src={ACONEX} alt="Aconex logo" />
                <Typography color="light.main" variant="subtitle1_HelveticaNeue_Regular">
                  Aconex
                </Typography>
              </div>
            </a>
          </div>
        </div>

        <div className="subPlatform">
          <div className="smallEllipseWrapper">
            <a
              onClick={(e) =>
                handleRedirectUrl(
                  e,
                  SUB_PLATFORMS.SMALL_ORBITS[clickedPlatform].LOGO1.URL,
                  clickedPlatform,
                )
              }
              className="smallEllipse subPlatformLogo subPlatformSE1 logoWrapper"
              href={SUB_PLATFORMS.SMALL_ORBITS[clickedPlatform].LOGO1.URL}
            >
              <div
                className={`platformLogoItem ${
                  SUB_PLATFORMS.SMALL_ORBITS[clickedPlatform].LOGO1.IS_COMING_SOON &&
                  'comingSoon'
                }`}
              >
                {SUB_PLATFORMS.SMALL_ORBITS[clickedPlatform].LOGO1.IMG}
                <Typography color="light.main" variant="subtitle1_HelveticaNeue_Regular">
                  {SUB_PLATFORMS.SMALL_ORBITS[clickedPlatform].LOGO1.TITLE}
                </Typography>
              </div>
            </a>
            <a
              onClick={(e) => {
                if (accessProgramView) {
                  handleRedirectUrl(
                    e,
                    SUB_PLATFORMS.SMALL_ORBITS[clickedPlatform].LOGO2.URL,
                    clickedPlatform,
                  );
                } else {
                  e.preventDefault();
                  setOpenBlocAccessModal(true);
                }
              }}
              className="smallEllipse subPlatformLogo subPlatformSE2 logoWrapper"
              href={
                accessProgramView
                  ? SUB_PLATFORMS.SMALL_ORBITS[clickedPlatform].LOGO2.URL
                  : '#'
              }
            >
              <div
                className={`platformLogoItem ${
                  SUB_PLATFORMS.SMALL_ORBITS[clickedPlatform].LOGO2.IS_COMING_SOON &&
                  'comingSoon'
                }`}
              >
                {SUB_PLATFORMS.SMALL_ORBITS[clickedPlatform].LOGO2.IMG}
                <Typography color="light.main" variant="subtitle1_HelveticaNeue_Regular">
                  {SUB_PLATFORMS.SMALL_ORBITS[clickedPlatform].LOGO2.TITLE}
                </Typography>
              </div>
            </a>
          </div>
          <div className="mediumEllipseWrapper">
            <a
              onClick={(e) =>
                handleRedirectUrl(
                  e,
                  SUB_PLATFORMS.MEDIUM_ORBITS[clickedPlatform].LOGO1.URL,
                  clickedPlatform,
                )
              }
              className="mediumEllipse subPlatformLogo subPlatformME1 logoWrapper"
              href={SUB_PLATFORMS.MEDIUM_ORBITS[clickedPlatform].LOGO1.URL}
            >
              <div
                className={`platformLogoItem ${
                  SUB_PLATFORMS.MEDIUM_ORBITS[clickedPlatform].LOGO1.IS_COMING_SOON &&
                  'comingSoon'
                }`}
              >
                {SUB_PLATFORMS.MEDIUM_ORBITS[clickedPlatform].LOGO1.IMG}
                <Typography color="light.main" variant="subtitle1_HelveticaNeue_Regular">
                  {SUB_PLATFORMS.MEDIUM_ORBITS[clickedPlatform].LOGO1.TITLE}
                </Typography>
              </div>
            </a>
            <a
              onClick={(e) =>
                handleRedirectUrl(
                  e,
                  SUB_PLATFORMS.MEDIUM_ORBITS[clickedPlatform].LOGO2.URL,
                  clickedPlatform,
                )
              }
              className="mediumEllipse subPlatformLogo subPlatformME2 logoWrapper"
              href={SUB_PLATFORMS.MEDIUM_ORBITS[clickedPlatform].LOGO2.URL}
            >
              <div
                className={`platformLogoItem ${
                  SUB_PLATFORMS.MEDIUM_ORBITS[clickedPlatform].LOGO2.IS_COMING_SOON &&
                  'comingSoon'
                }`}
              >
                {SUB_PLATFORMS.MEDIUM_ORBITS[clickedPlatform].LOGO2.IMG}
                <Typography color="light.main" variant="subtitle1_HelveticaNeue_Regular">
                  {SUB_PLATFORMS.MEDIUM_ORBITS[clickedPlatform].LOGO2.TITLE}
                </Typography>
              </div>
            </a>
            <a
              onClick={(e) => {
                if (hasAccessToSeniorManagementView) {
                  handleRedirectUrl(
                    e,
                    SUB_PLATFORMS.MEDIUM_ORBITS[clickedPlatform].LOGO3.URL,
                    clickedPlatform,
                  );
                } else {
                  e.preventDefault();
                  setOpenBlocAccessModal(true);
                }
              }}
              className="mediumEllipse subPlatformLogo subPlatformME3 logoWrapper"
              href={
                hasAccessToSeniorManagementView
                  ? SUB_PLATFORMS.MEDIUM_ORBITS[clickedPlatform].LOGO3.URL
                  : '#'
              }
            >
              <div
                className={`platformLogoItem ${
                  SUB_PLATFORMS.MEDIUM_ORBITS[clickedPlatform].LOGO3.IS_COMING_SOON &&
                  'comingSoon'
                }`}
              >
                {SUB_PLATFORMS.MEDIUM_ORBITS[clickedPlatform].LOGO3.IMG}
                <Typography color="light.main" variant="subtitle1_HelveticaNeue_Regular">
                  {SUB_PLATFORMS.MEDIUM_ORBITS[clickedPlatform].LOGO3.TITLE}
                </Typography>
              </div>
            </a>
            <a
              onClick={(e) =>
                handleRedirectUrl(
                  e,
                  SUB_PLATFORMS.MEDIUM_ORBITS[clickedPlatform]?.LOGO4?.URL,
                  clickedPlatform,
                )
              }
              className="mediumEllipse subPlatformLogo subPlatformME4 logoWrapper"
              href={SUB_PLATFORMS.MEDIUM_ORBITS[clickedPlatform]?.LOGO4?.URL}
            >
              <div
                className={`platformLogoItem ${
                  SUB_PLATFORMS.MEDIUM_ORBITS[clickedPlatform]?.LOGO4?.IS_COMING_SOON &&
                  'comingSoon'
                }`}
              >
                {SUB_PLATFORMS.MEDIUM_ORBITS[clickedPlatform]?.LOGO4?.IMG}
                <Typography color="light.main" variant="subtitle1_HelveticaNeue_Regular">
                  {SUB_PLATFORMS.MEDIUM_ORBITS[clickedPlatform]?.LOGO4?.TITLE}
                </Typography>
              </div>
            </a>
          </div>
        </div>

        <SplashScreenCircles />
      </div>

      {showSubPlatforms ? (
        <BackToBtn
          handleClick={() => {
            handleToggleOrbitsName(true);
            handleBackToPlatforms();
          }}
        />
      ) : null}

      {isAuthenticated && !showSubPlatforms && <ProgramSettingsBtn />}
      {showDownloadApk && (
        <DownloadPopin
          onClose={() => setDownloadApk(false)}
          platformName={platformName}
          handelOnClick={handelDownloadApk}
          isLoading={downloadLoading}
        />
      )}
      <ConfirmationPopup
        width="500px"
        toOpen={openBlocAccessModal}
        popupType={POPUP_TYPE.ACCESS_DENIED}
        onCancel={() => setOpenBlocAccessModal(false)}
        onClose={() => setOpenBlocAccessModal(false)}
      />
    </Wrapper>
  );
}

export default AppSplashPage;
