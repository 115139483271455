import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, FormControl, InputLabel, Stack, Typography } from '@mui/material';
import { createStructuredSelector } from 'reselect';
import { ArrowLeftIcon } from 'assets/svgs/componentsIcons';
import SmallCloseIcon from 'assets/svgs/componentsIcons/SmallCloseIcon';
import ActionButton from 'components/ActionButton';
import AvatarUpload from 'components/AvatarUpload';
import { BackButton } from 'components/Buttons';
import ConfirmationPopup from 'components/ConfirmationPopin';
import FormInput from 'components/FormInput';
import FormSelect from 'components/FormSelect';
import FormSwitch from 'components/FormSwitch';
import AutocompleteInput from 'components/AutocompleteInput';
import AppBody from 'components/StyledComponents/AppBody';
import PageHeader from 'components/StyledComponents/PageHeader';
import {
  EnumerationType,
  useGetEnumerationByTypeFromUserManagementQuery,
} from 'services/apis/enumeration';
import {
  useCreateExternalUserMutation,
  useLazyGetUserCompanyByIdQuery,
} from 'services/apis/user';
import {
  fillExternalUserForm,
  setSubmit,
} from 'services/slices/user-management/external-users';
import { POPUP_TYPE } from 'components/ConfirmationPopin/constants';
import { ChipStyle, ChipStyleWrapper } from 'components/FormSelect/Wrapper';
import { useLazyGetFilteredPlatformSettingsEntityQuery } from 'services/apis/platform-settings';
import { PLATFORM_SETTINGS_TYPES } from 'helpers/constants';
import { PLATFORM_SETTINGS_TYPE } from 'containers/ProjectAdministrationPlatformSettings/constants';
import FullPageLoader from 'components/FullPageLoader';
import { EXTERNAL_ROLE } from 'containers/ProjectAdministrationUserManagement/constants';
import { makeSelectExternalUser, makeSelectIsSubmit } from './selectors';
import AddExternalUsersWrapper from './Wrapper';

const stateSelector = createStructuredSelector({
  externalUser: makeSelectExternalUser,
  isSubmit: makeSelectIsSubmit,
});

function AddExternalUsers() {
  const { externalUser, isSubmit } = useSelector(stateSelector);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userId } = useParams();

  const [showCancelPopup, setShowCancelPopup] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [companyName, setCompanyName] = useState('');
  const [contractorList, setContractorList] = useState([]);

  const { data: externalRoles } = useGetEnumerationByTypeFromUserManagementQuery(
    EnumerationType.EXTERNAL_USER_ROLE_TYPE,
  );
  const [doCreateExternalUser, { isSuccess: successCreate }] =
    useCreateExternalUserMutation();
  const [doGetFilteredPlatformSettingsEntity, { isFetching: filterLoading }] =
    useLazyGetFilteredPlatformSettingsEntityQuery();
  const [doGetUserById] = useLazyGetUserCompanyByIdQuery();

  const updateExternalUserForm = (data) => {
    dispatch(fillExternalUserForm(data));
  };

  useEffect(() => {
    if (successCreate) setShowSuccessPopup(true);
  }, [successCreate]);

  useEffect(() => {
    if (userId) {
      doGetUserById(userId).then((result) => {
        dispatch(fillExternalUserForm(result?.data));
      });
    }
  }, [dispatch, doGetUserById, userId]);

  useEffect(() => {
    if (companyName.length >= 3) {
      doGetFilteredPlatformSettingsEntity(
        {
          type: PLATFORM_SETTINGS_TYPES.CONTRACTOR,
          name: companyName,
          page: 0,
          baseRoute: PLATFORM_SETTINGS_TYPE.CONTRACTOR.baseRoute,
        },
        false,
      ).then(({ data }) => {
        setContractorList(data?.content);
      });
    } else {
      setContractorList([]);
    }
  }, [companyName, doGetFilteredPlatformSettingsEntity]);

  const onSubmitForm = () => {
    if (
      externalUser?.externalRole?.code !== EXTERNAL_ROLE.EXTERNAL_CONTRACTOR ||
      externalUser?.linkedToAllCompanies ||
      externalUser?.companyList.length > 0
    ) {
      doCreateExternalUser(externalUser);
    } else if (!isSubmit) {
      dispatch(setSubmit(true));
    }
  };

  const isFieldValid = (fieldData) => !isSubmit || !!fieldData;

  const handelDeleteContractor = (company) => {
    updateExternalUserForm({
      companyList: externalUser.companyList?.filter((c) => c.id !== company.id),
    });
  };
  return (
    <AddExternalUsersWrapper>
      {filterLoading && <FullPageLoader />}

      <ConfirmationPopup
        toOpen={showCancelPopup}
        popupType={POPUP_TYPE.CREATE_EXTERNAL_USER__CANCEL}
        onConfirm={() => navigate(-1)}
        onCancel={() => setShowCancelPopup(false)}
        onClose={() => setShowCancelPopup(false)}
      />
      <ConfirmationPopup
        toOpen={showSuccessPopup}
        popupType={POPUP_TYPE.CREATE_EXTERNAL_USER__SUCCESS}
        onClose={() => {
          setShowSuccessPopup(false);
          navigate(-1);
        }}
      />
      <AppBody>
        <PageHeader>
          <Box className="top-header">
            <Box className="page-actions-wrapper">
              <Box className="heading-area">
                <BackButton type="button" onClick={() => navigate(-1)}>
                  <ArrowLeftIcon />
                </BackButton>
                <Box className="page-title-area">
                  <Typography variant="h3_HelveticaNeue_Bold" color="primary.main">
                    Edit External users
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Typography variant="subtitle1_HelveticaNeue_Regular" color="text.main">
              Here you can Edit an External User
            </Typography>
          </Box>
        </PageHeader>
        <Stack className="from-wrapper">
          <Stack>
            <FormControl className="inputs__label" variant="standard">
              <InputLabel shrink>
                <Typography color="text.darker" variant="h5_HelveticaNeue_Regular">
                  User avatar
                </Typography>
              </InputLabel>
            </FormControl>
            <AvatarUpload
              HideChangeAvatarButton
              maxSize={3000000}
              onChoose={(file) => {
                updateExternalUserForm({ avatar: file });
              }}
            />
          </Stack>
          <Stack className="form-split">
            <FormInput
              labelColor="text.darker"
              label="First Name"
              placeholder="First Name"
              onChange={(e) => updateExternalUserForm({ firstName: e.target.value })}
              value={externalUser?.firstName}
            />
            <FormInput
              labelColor="text.darker"
              label="Last Name"
              placeholder="Last Name"
              onChange={(e) => updateExternalUserForm({ lastName: e.target.value })}
              value={externalUser?.lastName}
            />
          </Stack>
          <Stack className="form-split">
            <FormInput
              labelColor="text.darker"
              label="Display Name"
              placeholder="Display Name"
              onChange={(e) => updateExternalUserForm({ fullName: e.target.value })}
              value={externalUser?.fullName}
            />
            <FormInput
              labelColor="text.darker"
              number
              label="Phone"
              placeholder="Phone"
              onChange={(e) => updateExternalUserForm({ mobilePhone: e.target.value })}
              value={externalUser?.mobilePhone}
            />
          </Stack>
          <Stack className="form-split">
            <FormSelect
              labelColor="text.darker"
              label="External Role*"
              menuItems={externalRoles}
              onChange={(e) => updateExternalUserForm({ externalRole: e.target.value })}
              value={externalRoles?.find(
                (item) => item.code === externalUser.externalRole?.code,
              )}
              valueKey="label"
              isValid={isFieldValid(externalUser?.externalRole)}
            />
            {externalUser.externalRole?.code === EXTERNAL_ROLE.EXTERNAL_CONTRACTOR &&
              !externalUser?.linkedToAllCompanies && (
                <Stack>
                  <AutocompleteInput
                    className="formWidth"
                    isWithCheckbox={false}
                    label="Contractors*"
                    placeholder="Type 3 characters to search..."
                    handleOnInputChange={(_, value, reason) => {
                      if (reason === 'input') {
                        setCompanyName(value);
                      }
                    }}
                    handleOnChange={(_, values) => {
                      const selectedValue = values[0];
                      const oldList = externalUser?.companyList || [];
                      if (
                        oldList.length === 0 ||
                        !oldList?.map((c) => c.name).includes(selectedValue.name)
                      ) {
                        updateExternalUserForm({
                          companyList: [...oldList, selectedValue],
                        });
                      }
                    }}
                    options={contractorList?.map((u) => {
                      return { ...u, companyName: u.name };
                    })}
                    inputValue={companyName}
                    valueKey="companyName"
                    error={isSubmit && externalUser.companyList.length === 0}
                  />
                  <ChipStyleWrapper>
                    {externalUser?.companyList?.map((company) => (
                      <ChipStyle
                        key={company?.name}
                        className="tagStyle"
                        label={company?.name}
                        onDelete={() => handelDeleteContractor(company)}
                        deleteIcon={
                          <Stack>
                            <SmallCloseIcon classname="deleteIcon" />
                          </Stack>
                        }
                      />
                    ))}
                  </ChipStyleWrapper>
                </Stack>
              )}
          </Stack>
          {externalUser.externalRole?.code === EXTERNAL_ROLE.EXTERNAL_CONTRACTOR && (
            <Stack className="form-split">
              <FormSwitch
                classname="switchForm"
                label="Is Linked to All Contractors?*"
                onChange={() =>
                  updateExternalUserForm({
                    linkedToAllCompanies: !externalUser?.linkedToAllCompanies,
                  })
                }
                checked={externalUser?.linkedToAllCompanies || false}
              />
            </Stack>
          )}
        </Stack>
      </AppBody>
      <Stack className="footer">
        <ActionButton
          classname="footer--back__button cancel  btn--reset"
          label="Cancel"
          onClick={() => setShowCancelPopup(true)}
        />
        <ActionButton
          classname="footer--next__button btn--reset"
          label="Save"
          onClick={onSubmitForm}
        />
      </Stack>
    </AddExternalUsersWrapper>
  );
}

export default AddExternalUsers;
