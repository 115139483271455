import React, {useEffect, useState} from 'react';
import {Stack, Typography} from '@mui/material';
import {useNavigate, useParams} from 'react-router-dom';
import {BackButton} from 'components/Buttons';
import {ArrowLeftIcon, PlusIcon} from 'assets/svgs/componentsIcons';
import CustomPagination from 'components/StyledComponents/Pagination';
import TabTitles from 'components/StyledComponents/TabTitles';
import {useGetUserAvatarByIdQuery, useLazyGetUserByIdQuery, useLazyGetUserCompanyByIdQuery,} from 'services/apis/user';
import {useDispatch, useSelector} from 'react-redux';
import {createStructuredSelector} from 'reselect';
import {useLazyGetUserProjectProfilesByPlatformAndUserQuery} from 'services/apis/user-project-profile';
import ConfirmationPopup from 'components/ConfirmationPopin';
import {POPUP_TYPE} from 'components/ConfirmationPopin/constants';
import {useLazyGetProfilesByPlatformAndIsExternalQuery} from 'services/apis/access-control/profile';
import {cleanManyAssignmentInfos, removeAllUpdatedItems,} from 'services/slices/user-management/user-project-profile';
import {SETTINGS_USERS} from 'routes/constants';
import FullPageLoader from 'components/FullPageLoader';
import {
  makeSelectSuccessFreeze,
  makeSelectSuccessUpdateInternalUser,
} from 'containers/ProjectAdministrationUserManagement/UserManagementItems/UserManagementItem/UserManagementOption/selectors';
import UserProjectProfileTable from './UserProjectProfileTable';
import {LAST_ACTION_TYPE, TABS_NAME} from './constants';
import {
  makeSelectAssignManyProjectsToUserSuccess,
  makeSelectIsExternal,
  makeSelectSelectedPlatform,
  makeSelectUpdatedItems,
  makeSelectUpdateUserResult,
} from './selectors';
import UserInformation from './UserInformation';
import AddEditUserPopup from './AddEditUserPopup';
import UserDetailedViewWrapper from './Wrapper';

const stateSelector = createStructuredSelector({
  isExternal: makeSelectIsExternal,
  updatedItems: makeSelectUpdatedItems,
  successUpdateInternalUser: makeSelectSuccessUpdateInternalUser,
  successFreeze: makeSelectSuccessFreeze,
  updateUserResult: makeSelectUpdateUserResult,
  assignManyProjectsToUserSuccess: makeSelectAssignManyProjectsToUserSuccess,
  selectedPlatform: makeSelectSelectedPlatform,
});

function UserDetailedView() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {userId} = useParams();
  const [page, setPage] = useState(0);
  const [activeTab, setActiveTab] = useState(TABS_NAME.COLLAB.code);
  const [openModal, setOpenModal] = useState(false);
  const [lastAction, setLastAction] = useState({});
  const [userAvatar, setUserAvatar] = useState('');
  const [userDetail, setUserDetail] = useState(null);
  const [showEditProfilePopup, setShowEditProfilePopup] = useState(false);
  const [refetch, setRefetch] = useState(false);
  const {
    isExternal,
    updatedItems,
    successFreeze,
    successUpdateInternalUser,
    updateUserResult,
    assignManyProjectsToUserSuccess,
    selectedPlatform,
  } = useSelector(stateSelector);
  const {data: avatar} = useGetUserAvatarByIdQuery(userId);
  const [doGetInternalUserById, {isFetching: internalUserLoading}] =
    useLazyGetUserByIdQuery(userId);
  const [doGetExternalUserById, {isFetching: externalUserLoading}] =
    useLazyGetUserCompanyByIdQuery();
  const [
    doGetUserProjectProfiles,
    {data: userProjectProfiles, isFetching: userProfileLoading},
  ] = useLazyGetUserProjectProfilesByPlatformAndUserQuery();

  const [doGetProfilesByPlatformAndIsExternal, {data: profiles}] = useLazyGetProfilesByPlatformAndIsExternalQuery();

  const getUserProjectProfiles = () => {
    doGetUserProjectProfiles({platform: activeTab, userId, page});
  };

  useEffect(() => {
    if (userId) {
      const data = isExternal
        ? doGetExternalUserById(userId)
        : doGetInternalUserById(userId);
      data.then((result) => {
        setUserDetail(result?.data);
      });
    }
  }, [isExternal, userId, successFreeze]);

  useEffect(() => {
    if (updateUserResult && successUpdateInternalUser) {
      setUserDetail(updateUserResult);
    }
  }, [updateUserResult, successUpdateInternalUser]);

  useEffect(() => {
    if (avatar) {
      setUserAvatar(URL.createObjectURL(avatar));
    }
  }, [avatar]);

  useEffect(() => {
    if (selectedPlatform && assignManyProjectsToUserSuccess) {
      setShowEditProfilePopup(false);
      setActiveTab(selectedPlatform);
      setRefetch(true);
      dispatch(cleanManyAssignmentInfos());
    }
  }, [selectedPlatform, assignManyProjectsToUserSuccess]);

  useEffect(() => {
    if (activeTab) {
      getUserProjectProfiles();
      setRefetch(false);
    }
  }, [activeTab, page, refetch]);

  // getProfiles
  useEffect(() => {
    if (userDetail && activeTab) {
      doGetProfilesByPlatformAndIsExternal({platform: activeTab, isExternal: userDetail?.external});
    }
  }, [activeTab, userDetail]);

  const handleActionWithModal = (
    hasUpdatedItems,
    actionWithModal,
    actionWithoutModal,
  ) => {
    if (hasUpdatedItems) {
      setOpenModal(true);
      actionWithModal();
    } else {
      actionWithoutModal();
    }
  };
  const pageChanged = (p) => {
    setPage(p);
  };
  const changePage = (_, p) => {
    const pageNumber = p - 1;
    handleActionWithModal(
      updatedItems?.length > 0,
      () => setLastAction({type: LAST_ACTION_TYPE.PAGER, data: pageNumber}),
      () => pageChanged(pageNumber),
    );
  };

  const tabChanged = (tab) => {
    setActiveTab(tab);
    setPage(0);
  };

  const handleTabChange = (newTab) => {
    handleActionWithModal(
      updatedItems?.length > 0,
      () => setLastAction({type: LAST_ACTION_TYPE.TAB, data: newTab}),
      () => tabChanged(newTab),
    );
  };

  const navigateToUserListing = () => {
    navigate(SETTINGS_USERS);
  };

  const handleOnClick = () => {
    handleActionWithModal(
      updatedItems?.length > 0,
      () => setLastAction({type: LAST_ACTION_TYPE.BACK_BTN, data: null}),
      () => navigateToUserListing(),
    );
  };

  const handleOpenCloseModal = () => {
    setOpenModal(false);
  };
  const onConfirm = () => {
    dispatch(removeAllUpdatedItems());
    switch (lastAction?.type) {
      case LAST_ACTION_TYPE.TAB:
        tabChanged(lastAction?.data);
        break;
      case LAST_ACTION_TYPE.PAGER:
        pageChanged(lastAction?.data);
        break;
      case LAST_ACTION_TYPE.BACK_BTN:
        navigateToUserListing();
        break;
      default:
        break;
    }
    setOpenModal(false);
  };

  const handleOpenAddEditUser = () => {
    setShowEditProfilePopup(true);
  };

  const handleCloseAddEditUser = () => {
    setShowEditProfilePopup(false);
  };

  return (
    <UserDetailedViewWrapper>
      {(internalUserLoading || externalUserLoading) && userProfileLoading && (
        <FullPageLoader/>
      )}
      {openModal && (
        <ConfirmationPopup
          width="500px"
          toOpen={openModal}
          popupType={POPUP_TYPE.EDIT_USER_PROJECT_PROFILE}
          onConfirm={onConfirm}
          onCancel={handleOpenCloseModal}
          onClose={handleOpenCloseModal}
        />
      )}
      {showEditProfilePopup && (
        <AddEditUserPopup
          userDetail={userDetail}
          toOpen={showEditProfilePopup}
          onClose={handleCloseAddEditUser}
          onCancel={handleCloseAddEditUser}
        />
      )}

      <Stack className="top-header">
        <Stack className="titled-header">
          <BackButton onClick={handleOnClick} type="button">
            <ArrowLeftIcon/>
          </BackButton>
          <Typography variant="h3_HelveticaNeue_Bold" color="primary.main">
            User Detailed View
          </Typography>
        </Stack>
        <Typography variant="subtitle1_HelveticaNeue_Regular" color="text.main">
          Here you can see user Detail
        </Typography>
      </Stack>
      <Stack className="sections">
        <UserInformation userAvatar={userAvatar} userDetail={userDetail}/>
        <Typography
          marginTop="25px"
          variant="h6_HelveticaNeue_Medium"
          color="primary.main"
        >
          Project List
        </Typography>
        <div className="tabsContainer">
          <TabTitles
            tabs={TABS_NAME}
            handleTabChange={handleTabChange}
            activeTab={activeTab}
          />
          <button type="button" className="addIcon" onClick={handleOpenAddEditUser}>
            <PlusIcon/>
          </button>
        </div>

        <UserProjectProfileTable
          userProjectProfiles={userProjectProfiles}
          handleGetUserProjectProfiles={getUserProjectProfiles}
          profiles={profiles}
        />
        {userProjectProfiles?.totalPages >= 1 && (
          <CustomPagination
            shape="rounded"
            page={page + 1}
            onChange={changePage}
            count={userProjectProfiles?.totalPages}
          />
        )}
      </Stack>
    </UserDetailedViewWrapper>
  );
}

export default UserDetailedView;
